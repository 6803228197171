.navtheme{
    background-color: #14A181 !important;
}

.logo{
    border-radius: 50%;
}

.navtext{
    color: white !important;
}